import styled from 'styled-components'

export const Container = styled.footer`
    background: #173746;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Content = styled.div`
    max-width: 238px;
    margin: auto auto;
    margin-bottom: 110px;

    & + div {
        margin-left: 0px;
    }

    @media (max-width: 768px) {
        margin-left: 40px;
        margin-bottom: 40px;

        & + div {
            margin-left: 40px;
        }
    }

    p {
        margin-bottom: 10px;
        font-family: "Avenir", Avenir, Montserrat;;
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
    }

    a {
        font-family: "Avenir", Avenir, Montserrat;;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ADB5BD;
        text-transform: none;
    }
`;

export const Download = styled.div`
    margin: 0 auto;
    margin-bottom: 110px;

    @media (max-width: 768px) {
        margin-left: 40px;        
    }
    
    p {
        font-family: "Avenir", Avenir, Montserrat;;
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
    }

    svg {
        color: #FFFFFF;
    }

    div {
        display: flex;
        
        @media (max-width: 768px) {
            flex-direction: column;
        }
        
        a {
            display: flex;
            margin-top: 10px;
            text-transform: none;
            
            & + a {
                margin-left: 90px;
            }

            @media (max-width: 768px) {
                & + a {
                    margin-left: 0px;
                    margin-top: 25px;
                }
            }
            
            div {
                text-align: left;
                justify-content: left;
                display: flex;
                flex-direction: column;
                
                p {
                    font-family: "Avenir", Avenir, Montserrat;;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px; 
                    color: #FFFFFF;
                }

                span {
                    font-family: "Avenir", Avenir, Montserrat;;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 21px;
                    color: #FFFFFF;
                }
            }
        }
    }
`;