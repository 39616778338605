import React from 'react';

import { IoLogoApple, IoLogoAndroid} from 'react-icons/io'

import { Container, 
         Content,
         Download } from './styles';

const Footer = () => {
    return (
        <>  
            <Container>
                
                <Content>
                    <p>Onde Estamos</p>
                    <a  target="_blank"
                        href="https://www.google.com/maps/place/Av.+Nascimento+de+Castro,+1881+-+Lagoa+Nova,+Natal+-+RN,+59056-450/@-5.812879,-35.2214182,3a,75y,18.41h,90t/data=!3m6!1e1!3m4!1sLhTG7a-sUYH4OOy_dOV-Rw!2e0!7i13312!8i6656!4m5!3m4!1s0x7b25550a156aa71:0xf986449d9fae96ec!8m2!3d-5.8128042!4d-35.2213937">
                        Av. Nascimento de Castro, 1881 Lagoa Seca - Natal/RN
                    </a>
                </Content>

                <Content>
                    <p>Contato</p>
                    <a target="_blank"
                       href="https://api.whatsapp.com/send?phone=5584991161258" >
                            +55 84 99116-1258
                    </a> <br />
                    <a target="_blank"
                        href="mailto:contato@strategi.in">
                        contato@strategi.in
                    </a>
                </Content>

                <Download>
                    <p>Baixe o App</p>
                    
                    <div>
                        <a 
                            target="_blank"
                            href="https://apps.apple.com/br/app/strategi-app/id1473671622?mt=8"
                        >
                            <IoLogoApple size={40} />
                            <div>
                                <p>Disponivel na</p>
                                <span>App Store</span>
                            </div>
                        </a>

                        <a  target="_blank"
                            href="https://play.google.com/store/apps/details?id=in.strategi&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        >
                            <IoLogoAndroid size={40} />
                            <div>
                                <p>Disponivel na</p>
                                <span>Google Play</span>
                            </div>
                        </a>
                    </div>
                </Download>

            </Container>
        </>
    )
}

export default Footer
